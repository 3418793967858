import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TariffCardOptionProps } from '@/shared/ui-kit/tariff-card/tariff-card-option/tariff-card-option.types';
import { BUILDING_TYPE, ServiceHomeInternet } from '@/store/api/service-home-internet/service-home-internet.type';
import { HomeInternetTariffMappedOptions } from '@/modules/tariff/shared/helpers';

export type ServiceItem = Pick<ServiceHomeInternet,
  'building_type' |
  'name' |
  'localized_name' |
  'affects_promotion_shpd' |
  'description' |
  'old_shpd_id' |
  'prices' |
  'service_type' |
  'shpd_id' 
> & {
  slider_value: number;
};
export type AdditionalServiceItem = Pick<ServiceHomeInternet,
  'name' |
  'localized_name' |
  'shpd_id' |
  'old_shpd_id' |
  'service_type' |
  'building_type' |
  'prices' |
  'description' |
  'promotion_period' |
  'promotion_description' |
  'affects_promotion_shpd' |
  'order_with_mobile_tariff' |
  'default_tariff_home_internets' |
  'service'
> & {
  added: boolean;
  background_image?: string;
};

const initialState = {
  status: {
    added: false,
    touched: false,
  },
  buildingType: BUILDING_TYPE.APARTMENT,
  speed: <ServiceItem>{},
  tv: <ServiceItem>{},
  optionsHomeInternet: <TariffCardOptionProps[]>[],
  homeInternetTouchedServices: <AdditionalServiceItem[]>[],
  totalPriceHomeInternet: 0,
  promotionTotalPriceHomeInternet: <number | undefined | null>null,
  homeInternetTariff: <HomeInternetTariffMappedOptions | null>null,
};

export const HomeInternetSlice = createSlice({
  name: 'homeInternet',
  initialState,
  reducers: {
    changeHomeInternetTariff: (state, action: PayloadAction<HomeInternetTariffMappedOptions | null>) => {
      state.homeInternetTariff = action.payload;
    },
    changeBuildingType: (state, action: PayloadAction<BUILDING_TYPE>) => {
      state.buildingType = action.payload;
    },
    changeSpeed: (state, action: PayloadAction<ServiceItem>) => {
      state.speed = action.payload;
    },
    changeTv: (state, action: PayloadAction<ServiceItem>) => {
      state.tv = action.payload;
    },
    changeOptionsHomeInternet: (state, action: PayloadAction<TariffCardOptionProps[]>) => {
      state.optionsHomeInternet = action.payload;
    },
    changeHomeInternetServices: (state, action: PayloadAction<AdditionalServiceItem[]>) => {
      state.homeInternetTouchedServices = action.payload;
    },
    changeTotalPriceHomeInternet: (state, action: PayloadAction<number>) => {
      state.totalPriceHomeInternet = action.payload;
    },
    changePromotionTotalPriceHomeInternet: (state, action: PayloadAction<number | undefined | null>) => {
      state.promotionTotalPriceHomeInternet = action.payload;
    },
    changeHomeInternetStatus: (state, action: PayloadAction<boolean>) => {
      state.status.added = action.payload;
      state.status.touched = true;
    },
    resetHomeInternetToInitial: (state) => {
      state.status = initialState.status;
      state.homeInternetTariff = initialState.homeInternetTariff;
      state.buildingType = initialState.buildingType;
      state.homeInternetTouchedServices = initialState.homeInternetTouchedServices;
      state.speed = initialState.speed;
      state.tv = initialState.tv;
      state.optionsHomeInternet = initialState.optionsHomeInternet;
      state.totalPriceHomeInternet = initialState.totalPriceHomeInternet;
      state.promotionTotalPriceHomeInternet = initialState.promotionTotalPriceHomeInternet;
    },
  },
});

export const {
  changeHomeInternetTariff,
  changeBuildingType,
  changeSpeed,
  changeTv,
  changeOptionsHomeInternet,
  changeHomeInternetServices,
  changeTotalPriceHomeInternet,
  changePromotionTotalPriceHomeInternet,
  changeHomeInternetStatus,
  resetHomeInternetToInitial,
} = HomeInternetSlice.actions;

export default HomeInternetSlice.reducer;
