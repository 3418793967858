import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { Router } from 'next/router';
import { Provider as ReduxProvider } from 'react-redux';
import { YMaps } from '@pbe/react-yandex-maps';
import { MantineProvider } from '@mantine/core';
import nProgress from 'nprogress';
import useUtmParameters from '@/shared/lib/use-utm-parameters';
import { fixButtonPosition } from '@/shared/lib/chat2desk-utils';
import { META_DATA_CONSTANTS } from '@/shared/constants/meta-data';
import { Path } from '@/shared/constants/links';
import { apiEndpoints } from '@/shared/constants/links';
import { fetchEndpointData } from '@/shared/lib/request-api';
import { AboutCompanyEntity, AboutCompanyType } from '@/store/api/about-company/about-company.type';
import { Layout } from '@/layout/layout';
import CookiesModal from '@/widgets/cookies/cookies';
import store from '@/store/store';
import { Chat2Desk } from '@/widgets/chat2desk/chat2desk';
import { Popups } from '@/widgets/popup';
import { Meta } from '@/widgets/meta';
import ErrorBoundary from '@/widgets/error-handler/error-handler';
import 'nprogress/nprogress.css';
import '@/styles/reset.scss';
import '@/styles/initial.scss';
import '@/styles/global.scss';
import '@/styles/critical.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);
Router.events.on('routeChangeComplete', fixButtonPosition);

type CustomAppProps = {
  legalData?: Pick<AboutCompanyType, 'privacy_policy'>;
};

export default function VolnaApp({ Component, pageProps, legalData }: AppProps & CustomAppProps) {
  useUtmParameters();

  return (
    <>
      <Head>
        <title>{META_DATA_CONSTANTS[Path.main].title}</title>
        <meta
          name='description'
          content='Официальный сайт оператора сотовой связи в Крыму — &amp;quot;Волна мобайл&amp;quot;. Доступные цены на мобильную связь и интернет на территории Республики Крым и г. Севастополь.'
        />
        <meta property='og:title' content='Мобильная связь в Крыму - сотовый оператор "Волна мобайл"' />
        <meta
          property='og:description'
          content='Официальный сайт оператора сотовой связи в Крыму — "Волна мобайл". Доступные цены на мобильную связь и интернет на территории Республики Крым и г. Севастополь.'
        />
        <meta property='og:type' content='website' />
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, maximum-scale=1' />
        <meta name='google-site-verification' content='m1XqRWi1lSXLpW39mA7KpGpKwOziKJAE31OcKycqrHA' />
        <meta name='yandex-verification' content='657a3822908e3b0c' />
        <link rel='icon' type='image/x-icon' href='public/assets/img/favicon.ico' />
        <link rel='icon' href='/volnamobile.ico' />
        <link
          fetchPriority='high'
          rel='preload'
          href='/assets/fonts/roboto/400.woff2'
          as='font'
          type='font/woff2'
          crossOrigin=''
        />
        <link
          fetchPriority='high'
          rel='preload'
          href='/assets/fonts/roboto/500.woff2'
          as='font'
          type='font/woff2'
          crossOrigin=''
        />
        <link
          fetchPriority='high'
          rel='preload'
          href='/assets/fonts/ttNormsPro/500.woff2'
          as='font'
          type='font/woff2'
          crossOrigin=''
        />
        <link
          fetchPriority='high'
          rel='preload'
          href='/assets/fonts/Caveat.ttf'
          as='font'
          type='font/ttf'
          crossOrigin=''
        />
        <link
          fetchPriority='high'
          rel='preload'
          href='/assets/fonts/Manrope.ttf'
          as='font'
          type='font/ttf'
          crossOrigin=''
        />
        <noscript>
          <div>
            <img src='https://mc.yandex.ru/watch/37649870' style={{ position: 'absolute', left: '-9999px' }} alt='' />
          </div>
        </noscript>
      </Head>
      <Script src='/static/yandex-metrika.js' strategy='lazyOnload' />
      <Script src='https://livechatv2.chat2desk.com/packs/ie-11-support.js' strategy='lazyOnload'></Script>

      <ReduxProvider store={store}>
        <ErrorBoundary>
          <MantineProvider withGlobalStyles withNormalizeCSS>
            <YMaps>
              <Layout legalData={legalData}>
                <Chat2Desk />
                <CookiesModal />
                <Popups />
                <Meta />
                <Component {...pageProps} />
              </Layout>
            </YMaps>
          </MantineProvider>
        </ErrorBoundary>
      </ReduxProvider>
    </>
  );
}

VolnaApp.getInitialProps = async (context: AppContext): Promise<CustomAppProps & AppInitialProps> => {
  const ctx = await App.getInitialProps(context);
  const response = await fetchEndpointData<AboutCompanyEntity>(
    apiEndpoints.aboutCompany,
    { populate: ['privacy_policy'] },
    'LEGAL DATA',
  );

  // Оставляем только privacy_policy
  const legalData: Pick<AboutCompanyType, 'privacy_policy'> | undefined = response?.data?.attributes
    ? { privacy_policy: response.data.attributes.privacy_policy }
    : undefined;

  return {
    ...ctx,
    legalData
  };
};
