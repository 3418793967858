import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '@/shared/constants/links';
import { addingParameters } from '@/shared/lib/adding-parameters';
import { StrapiQueryParams } from '@/shared/types/strapi.types';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { inviteFriendEntity } from './invite-friend.type';

export const inviteFriendApi = createApi({
  reducerPath: 'inviteFriendApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.inviteFriend),

  endpoints: (builder) => ({
    getInviteFriendApi: builder.query<inviteFriendEntity, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
  }),
});

export const {
  useGetInviteFriendApiQuery,
} = inviteFriendApi;
