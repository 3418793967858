import { useEffect } from 'react';
import { useGetAboutCompanyQuery } from '@/store/api/about-company/about-company.api';
import { AboutCompanyType } from '@/store/api/about-company/about-company.type';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { changePrivacyPolicy } from '@/store/slice/app.slice';
import { apiEndpoints } from './links';

export const useLegalData = (legalData?: Pick<AboutCompanyType, 'privacy_policy'>) => {
  const { data: clientData } = useGetAboutCompanyQuery({ populate: 'privacy_policy' }, { skip: !!legalData });
  const { privacy_policy } = useAppSelector(({ app }) => app);
  const dispatch = useAppDispatch();

  const currentPrivacyPolicy = legalData?.privacy_policy || clientData?.data?.attributes?.privacy_policy;
  const privacyPolicyFile = currentPrivacyPolicy?.data?.[0];
  const url = privacyPolicyFile
    ? `/${apiEndpoints.files}/${privacyPolicyFile?.attributes?.hash}${privacyPolicyFile?.attributes?.ext}`
    : '';

  useEffect(() => {
    if (!privacy_policy) {
      dispatch(changePrivacyPolicy(url));
    }
  }, [dispatch, privacy_policy, url]);
};