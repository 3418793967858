import Link from 'next/link';
import cn from 'classnames';
import { VkIcon, TelegramIcon, YouTubeIcon, DzenIcon, PCLogo } from '@/shared/assets/svg';
import { LINKS } from '@/shared/constants/links';
import styles from './socials.module.scss';

export const Socials = ({ className }: { className?: string }) => {
  const data = [
    { link: LINKS.VK, icon: <VkIcon /> },
    { link: LINKS.YOUTUBE, icon: <YouTubeIcon /> },
    // { link: LINKS.TELEGRAM, icon: <TelegramIcon /> },
    { link: LINKS.DZEN, icon: <DzenIcon /> },
  ];

  return (
    <div className={cn(styles.container, className)}>
      {data.map(({ link, icon }) => (
        <Link target='_blank' href={link} key={link}>
          {icon}
        </Link>        
      ))}
    </div>
  );
};
