import { FC } from 'react';
import { CONSTANTS } from '@/widgets/form/constants';
import { SELECTED_ICON, SelectedItem } from '../selected-item';

interface SelectedPointProps {
  confirmation?: boolean;
  selectedPoint?: string;
}
export const SelectedPoint: FC<SelectedPointProps> = ({ confirmation, selectedPoint }) => {
  if (selectedPoint !== '') return (
    <SelectedItem
      icon={SELECTED_ICON.POINT}
      label={CONSTANTS.map.selectedLabel}
      value={selectedPoint}
      confirmation={confirmation}
    />
  );
};
