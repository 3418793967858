import { StrapiCollectionResponse, StrapiCollectionResponseMappedType, StrapiSingleTypeResponse } from '../types/strapi.types';

export function strapiCollectionResponseMapper<T extends object>(
  response?: StrapiCollectionResponse<T>,
): StrapiCollectionResponseMappedType<T> {
  if (response?.data) {
    return response.data.map(({ attributes, id }) => ({ ...attributes, id: id }));
  } else {
    return [];
  }
}

export function strapiSingleResponseMapper<T extends object>(
  response?: StrapiSingleTypeResponse<T>
): T | null {
  if (response?.data?.attributes) {
    return { ...response.data.attributes, id: response.data.id };
  } else {
    return null;
  }
}