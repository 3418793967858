export enum LINKS {
  BEAUTIFUL_NUMBERS = 'https://volnamobile.ru/numbers/',
  VOLNA_SALE = 'https://sale.volnamobile.ru/',
  VOLNA_SALE_AUTH = 'https://sale-gw.volnamobile.ru/auth/api/v1/Auth/login',
  VOLNA_SALE_TELEGRAM = 'https://t.me/Volna_Sale_Infobot',
  ESIM = 'https://volnamobile.ru/esim/',
  PERSONAL_ACCOUNT = 'https://lk-new.volnamobile.ru/',
  VK = 'https://vk.com/volnamobile',
  VK_SHARE_LINK = 'https://vk.com/share.php?url={url}',
  WHATSAPP_SHARE_LINK = 'https://wa.me/?text={url}',
  TELEGRAM_SHARE_LINK = 'https://t.me/share/url?url={url}',
  TELEGRAM_SKATE = 'https://t.me/volna_sk8',
  YOUTUBE = 'https://www.youtube.com/channel/UCBE0ytesPsF1BAbiRqMYTJw',
  TELEGRAM = 'https://t.me/Volna_chatbot',
  DZEN = 'https://dzen.ru/volna_techno',
  HH = 'https://hh.ru/employer/10582846',
  GOOGLE_PLAY = 'https://redirect.appmetrica.yandex.com/serve/1180795317767977724',
  RU_STORE = 'https://redirect.appmetrica.yandex.com/serve/1180923242865509178',
  PAY = 'https://volnamobile.ru/pay/',
  HOME_INTERNET = 'https://volnamobile.ru/services/home/',
  BEAUTIFUL_NUMBERS_BLACK_FRIDAY_FILE = 'https://volnamobile.ru/api/files/Volna_chernaya_pyatnicza_dlya_zagruzki_na_sajt_ea32b07577.pdf',
}

export const PathLabels = {
  main: 'Главная',
  services: {
    root: 'Услуги',
    all: 'Все услуги',
    mobile: 'Мобильная связь',
    categories: {
      popular: 'Самые популярные',
      mobile: 'Мобильная связь',
      homeInternet: 'Домашний интернет',
      fast: 'Быстрые команды',
      special: 'Подписки и сервисы',
    },
  },
  tariffMobile: {
    root: 'Мобильная связь',
    all: 'Все тарифы',
    archive: 'Архивные тарифы',
  },
  tariffHomeInternet: {
    root: 'Домашний интернет',
    all: 'Все тарифы',
  },
  news: 'Новости и акции',
  aboutCompany: 'О компании',
  privacyPolicy: 'Правила обработки персональных данных',
  activation: 'SIM-карта с онлайн-активацией',
  faq: {
    root: 'Помощь и поддержка',
    search: 'Результаты поиска',
    app: 'Мобильное приложение',
    mobile: 'Мобильная связь',
  },
  skatepark: 'Скейт-парк',
  litres: 'Литрес',
  sale: 'Волна SALE',
  kaspersky: 'Касперский',
  promotions: 'Бери всё',
  sberbox: 'Аренда SberBox',
  viju: 'Подписка Viju',
  inviteFriend: 'Приведи друга',
  premier: 'Подписка Premier',
  volnaSale: 'Волна SALE',
  deleteAccount: 'Удаление аккаунта',
};

export const Path = {
  main: '/',
  services: {
    root: '/services',
    popular: '/services/popular',
    mobile: '/services/mobile',
    homeInternet: '/services/home-internet',
    fast: '/services/fast',
    special: '/services/special',
    sberbox: '/services/sberbox',
    viju: '/services/viju',
    premier: '/services/premier',
  },
  tariffMobile: {
    root: '/tariffs',
    archive: '/tariffs/archive',
  },
  tariffHomeInternet: {
    root: '/home-internet',
    mono: '/home-internet/mono',
    convergent: '/home-internet/convergent',
    diy: '/home-internet/diy',
    evpatoria: '/home-internet/evpatoria',
    sevastopolskij: '/home-internet/sevastopolskij'
  },
  news: '/news',
  tariffServices: 'tariff-services/activation',
  aboutCompany: '/about-company',
  privacyPolicy: '/about-company/privacy-policy-mobile',
  faq: {
    root: '/faq',
    categories: '/faq/categories',
    questions: '/faq/questions',
    answer: '/faq/answer',
    searchResults: '/faq/search-results',
    app: '/faq/mobile-app',
    mobile: '/faq/categories/mobile',
    homeInternetHelp: '/faq/home-internet-help',
  },
  replenishment: {
    root: '/replenishment',
  },
  pointsOfSale: '/points-of-sale',
  // esim: '/esim',
  skatepark: '/skatepark',
  litres: '/litres',
  notFound: '/404',
  kaspersky: '/kaspersky',
  viju: '/viju',
  promotions: '/promotions',
  sale: '/sale',
  inviteFriend: '/invite-friend',
  volnaSale: '/volna-sale',
  loadingError: '/500',
  deleteAccount: '/delete-account',
};

export const apiEndpoints = {
  aboutCompany: 'api/about-company',
  articles: 'api/articles',
  promotions: 'api/beri-vsye',
  salesPoints: 'api/sales-points',
  services: 'api/services',
  serviceCategories: 'api/service-categories',
  serviceSubcategories: 'api/service-subcategories',
  serviceHomeInternets: 'api/service-home-internets',
  tariffs: 'api/tariffs',
  tariffShpds: 'api/tariff-mobile-shpds',
  tariffHomeInternets: 'api/tariff-home-internets',
  tariffHomeInternetCategories: 'api/tariff-home-internet-categories',
  mainPage: 'api/main-page',
  precrm: 'api/precrm',
  orderProcessingService: 'api/order-processing',
  paymentAdapter: 'api/payment-adapter',
  shpdMatrix: 'api/shpd-matrix',
  faq: 'api/faqs',
  faqCategories: 'api/faq-categories',
  landings: 'api/landings',
  files: 'api/files',
  mobileApp: 'api/mobile-app',
  tariffFeatures: 'api/tariff-features',
  popups: 'api/popups',
  inviteFriend: 'api/invite-friend',
  email: 'api/email',
  meta: 'api/metas',
  referralSystem: 'api/referral-system',
};

export const BASE_URL = process.env.NEXT_PUBLIC_STRAPI_HOST;
