import { createApi } from '@reduxjs/toolkit/query/react';
import { StrapiQueryParams, StrapiSingleTypeResponse } from '@/shared/types/strapi.types';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { addingParameters } from '@/shared/lib/adding-parameters';
import { apiEndpoints } from '@/shared/constants/links';
import { TariffMobileShpd, TariffMobileShpdEntity } from './tariff-mobile-shpd.type';

export const tariffShpdApi = createApi({
  reducerPath: 'tariffShpdApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.tariffShpds),
  endpoints: (builder) => ({
    getMobileTariffShpds: builder.query<TariffMobileShpdEntity, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
    getMobileTariffShpd: builder.query<StrapiSingleTypeResponse<TariffMobileShpd>, { params: StrapiQueryParams; id: number }>({
      query: ({ params, id }) => {
        return {
          url: `/${id}` + addingParameters({ params }),
        };
      },
    }),
  }),
});

export const { useGetMobileTariffShpdsQuery, useGetMobileTariffShpdQuery } = tariffShpdApi;
