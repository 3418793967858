import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Collapse, Accordion } from '@mantine/core';
import cn from 'classnames';
import { ArrowUp } from '@/shared/assets/svg';
import { AgreementLink, Modal } from '@/shared/ui-kit';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { Path } from '@/shared/constants/links';
import { cookies } from './constans';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { acceptCookies } from '@/store/slice/app.slice';
import { storage } from '@/shared/lib/storage';
import styles from './cookies.module.scss';

const CookiesModal = () => {
  const [expanded, setExpanded] = useState(false);
  const [detailsOpened, setDetailsOpened] = useState(false);
  const { isMobile, isDesktop } = useMediaQuery();
  const router = useRouter();
  const isCookiesAccepted = useAppSelector((state) => state.app.isCookiesAccepted);
  const dispatch = useAppDispatch();

  const isTariffMobilePage = router.pathname.includes(Path.tariffMobile.root);
  const isTariffHomeInternetPage = router.pathname.includes(Path.tariffHomeInternet.root);

  useEffect(() => {
    const cookieStatus = storage.get('cookiesAccepted');

    if (cookieStatus === 'true') {
      dispatch(acceptCookies());
    }
  }, []);

  const handleAccept = () => {
    storage.set('cookiesAccepted', 'true');
    handleClose();
  };

  const handleClose = () => {
    dispatch(acceptCookies());
  };

  const toggleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleDetailsOpen = () => {
    setDetailsOpened(true);
  };

  const handleDetailsClose = () => {
    setDetailsOpened(false);
  };

  return (
    <>
      <Modal
        withoutCircle
        className={cn(styles.modal, {
          [styles.tariffMobilePage]: isTariffMobilePage,
          [styles.tariffHomeInternetPage]: isTariffHomeInternetPage,
        })}
        classNames={{
          content: cn(styles.content),
        }}
        opened={!isCookiesAccepted}
        onClose={() => {}}
        withCloseButton={false}
        withOverlay={false}
        size={'xl'}
        lockScroll={false}
      >
        <div className={styles.modalContainer}>
          <div>
            <div className={styles.section}>
              <span className={styles.title}>Файлы cookies помогут нашему сайту стать удобнее для Вас.</span>
              {isMobile && (
                <div className={`${styles.arrow} ${expanded ? styles.expanded : ''}`} onClick={toggleExpand}>
                  <ArrowUp />
                </div>
              )}
            </div>
            <Collapse in={!isMobile || expanded}>
              <AgreementLink
                text='Отключить или настроить можно в браузере. Продолжая использовать наш сайт, вы автоматически даете
                согласие на обработку файлов Cookies и других пользовательских данных, а также согласие на обработку
                персональных данных при обслуживании в онлайн-чате, в соответствии с Политикой обработки персональных данных.'
                linkText='Политикой обработки персональных данных.'
                className={styles.subTitle}
              />
            </Collapse>
          </div>

          <div className={styles.btnWrapper}>
            <button className={styles.btn} onClick={handleAccept}>
              Хорошо
            </button>
            <button className={styles.btnMore} onClick={handleDetailsOpen}>
              Подробнее
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        size={isDesktop ? '800px' : '640px'}
        className={styles.detailsModal}
        opened={detailsOpened}
        onClose={handleDetailsClose}
        withinPortal
      >
        <div className={styles.details}>
          <h1 className={styles.title}>Политика обработки файлов cookie</h1>
          <span className={styles.subTitle}>
            Посещая сайт ООО «КТК ТЕЛЕКОМ» по адресу volnamobile.ru в сети «Интернет», вы соглашаетесь с настоящей
            политикой, в том числе с тем, что ООО «КТК ТЕЛЕКОМ» может использовать файлы cookie и иные данные для их
            последующей обработки системами Яндекс.Метрика и др., а также может передавать их третьим
            лицам для проведения исследований, выполнения работ или оказания услуг.
          </span>
          <div className={styles.answer}>
            <Accordion
              transitionDuration={700}
              multiple
              variant='contained'
              radius='lg'
              classNames={{
                item: styles.accordion__item,
                control: styles.accordion__control,
                chevron: styles.accordion__chevron,
                content: styles.accordion__content,
              }}
            >
              {cookies.map(({ id, question, answer }) => (
                <Accordion.Item value={question} key={id}>
                  <Accordion.Control>
                    <span className={styles.question}>{question}</span>
                  </Accordion.Control>
                  <Accordion.Panel key={id}>
                    <div key={id}>
                      <div key={answer}>
                        <span className={styles.answer}>{answer}</span>
                      </div>
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CookiesModal;
