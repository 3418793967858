import React, { FC, ReactNode, RefObject, useState } from 'react';
import { Input, InputProps } from '@mantine/core';
import cn from 'classnames';
import { FactoryArg } from 'imask';
import { IMaskInput, IMask } from 'react-imask';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { Tooltip } from '@/shared/ui-kit';
import { CloseBtn } from '@/shared/assets/icons';
import { volnaTheme as theme } from '@/shared/constants/theme';
import { ErrorIcon } from '@/shared/assets/svg';
import styles from './phone-input.module.scss';

interface PhoneInputProps extends InputProps {
  onChange?: (unmasked: string, masked?: string) => void;
  darkBackground?: boolean;
  label?: string | ReactNode;
  mask?: FactoryArg;
  stringMask?: string;
  placeholder?: string;
  initialValue?: string;
  inputRef?: RefObject<HTMLInputElement>;
  value?: string;
  labelStyles?: string;
  baseColor?: boolean;
  isInviteFriend?: boolean;
}

export const PhoneInput: FC<PhoneInputProps> = ({
  onChange: changeHandler,
  darkBackground,
  error,
  disabled,
  label,
  mask,
  stringMask,
  placeholder = '+7 (___) ___ - __ - __',
  initialValue,
  className,
  inputRef,
  labelStyles,
  baseColor,
  isInviteFriend,
  ...props
}) => {
  const { isDesktop } = useMediaQuery();
  const [value, setValue] = useState(initialValue || '');
  const [isActive, setIsActive] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const masked = IMask.createMask(
    mask || [
      {
        mask: '',
      },
      {
        mask: '{+7} ',
        lazy: false,
        placeholderChar: '_',
      },
      {
        mask: stringMask || '+7 ({9}00) 000 - 00 - 00',
        lazy: false,
        placeholderChar: '_',
      },
    ],
  );

  const onChange = (val: string) => {
    changeHandler && changeHandler(masked.unmaskedValue, val);
    setValue(val);
  };

  const onClear = () => {
    setValue('');
    masked.value = '';
    changeHandler && changeHandler(masked.unmaskedValue);
  };

  return (
    <Input.Wrapper
      classNames={{
        root: cn(styles.wrapper__root, darkBackground && styles.dark, className),
        label: cn(styles.wrapper__label, labelStyles),
        error: styles.wrapper__error,
      }}
      error={!isInviteFriend ? error : undefined} // не покажем текст ошибки, если это стр Пригласи друга
      label={label}
    >
      <Input
        classNames={{
          input: cn(styles.input__input, { [styles.base]: baseColor }),
          rightSection: styles.input__right_section,
          wrapper: styles.input__wrapper,
        }}
        inputRef={inputRef}
        disabled={disabled}
        error={error}
        value={value}
        placeholder={placeholder}
        onInput={(event) => onChange(event.currentTarget.value)}
        onFocus={() => setIsActive(true)}
        onFocusCapture={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        onBlurCapture={() => setIsActive(false)}
        component={IMaskInput}
        mask={masked}
        rightSectionWidth={50}
        rightSection={
          error ? (
            isInviteFriend ? (
              <Tooltip
                label={error}
                position="top"
                withArrow
                opened={isTooltipOpen}
              >
                <span
                  className={styles.iconWithTooltip}
                  onMouseEnter={() => isDesktop && setIsTooltipOpen(true)}
                  onMouseLeave={() => isDesktop && setIsTooltipOpen(false)}
                  onClick={() => !isDesktop && setIsTooltipOpen((prev) => !prev)}
                >
                  <ErrorIcon />
                </span>
              </Tooltip>
            ) : (
              <ErrorIcon />
            )
          ) : (
            <button
              type='button'
              onClick={onClear}
              className={cn(styles.closeBtn, { [styles.visible]: value && isActive })}
            >
              <CloseBtn color={darkBackground ? theme.colors.base[200] : theme.colors.base[400]} />
            </button>
          )
        }
        {...props}
      />
    </Input.Wrapper>
  );
};
