import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MobileTariffMappedOptions, MobileTariffShpdMappedOptions } from '@/modules/tariff/shared/helpers';
import { ServiceEntity } from '../api/service/service.type';

export type MobileTariffSliceItem = Pick<
  MobileTariffMappedOptions,
  | 'localized_name'
  | 'name'
  | 'text'
  | 'optionsMobile'
  | 'price'
  | 'promotion_price'
  | 'promotion_period'
  | 'promotion_description'
  | 'features'
> & { offer_file?: string };

export type MobileTariffShpdSliceItem = Pick<
  MobileTariffShpdMappedOptions,
  | 'localized_name'
  | 'name'
  | 'shpd_id_apartment'
  | 'shpd_id_house'
  | 'optionsMobile'
  | 'shpd_prices'
  | 'feautures_construktor'
> & { affects_shpd_promotion_ids?: number[]; shpd_pdf?: string };

export type MobileService = Pick<ServiceEntity, 'serv_id' | 'shpd_serv_id' | 'title' > & {
  added: boolean;
  servicePrice: number;
  oldPrice?: number;
  period?: string;
  description: string;
};

const initialState = {
  status: {
    added: false,
    touched: false,
  },
  mobileTariff: <MobileTariffSliceItem>{
    localized_name: '',
    name: '',
    text: '',
    shpd_prices: {
      promotion_price: null,
      evpatoria_price: 0,
      price_apartment: 0,
      price_house: 0,
    },
    price: 0,
    promotion_price: undefined,
    promotion_period: '',
    promotion_description: '',
    shpd_id_apartment: 0,
    shpd_id_house: 0,
    optionsMobile: [],
    promo_resource_balances: [],
    affects_shpd_promotion: [],
    offer_file: '',
    shpd_pdf: '',
    features: [],
  },
  mobileTariffShpd: <MobileTariffShpdSliceItem>{
    localized_name: '',
    name: '',
    shpd_prices: {
      promotion_price: null,
      evpatoria_price: 0,
      price_apartment: 0,
      price_house: 0,
    },
    promotion_period: '',
    has_promotion_tag: false,
    shpd_id_apartment: 0,
    shpd_id_house: 0,
    optionsMobile: [],
    affects_shpd_promotion_ids: [],
    shpd_pdf: '',
    features: [],
    feautures_construktor: [],
  },
  mobileTouchedServices: <MobileService[]>[],
  totalPriceMobile: 0,
  promotionTotalPriceMobile: <number | undefined | null>null,
  totalPriceMobileShpd: 0,
  promotionTotalPriceMobileShpd: <number | undefined | null>null,
};

export const MobileTariffSlice = createSlice({
  name: 'mobileTariff',
  initialState,
  reducers: {
    changeMobileStatus: (state, action: PayloadAction<boolean>) => {
      state.status.added = action.payload;
      state.status.touched = true;
    },
    changeMobileTariff: (state, action: PayloadAction<MobileTariffSliceItem>) => {
      state.status.added = true;
      state.status.touched = true;
      state.mobileTariff = action.payload || initialState.mobileTariff;
    },
    changeMobileTariffShpd: (state, action: PayloadAction<MobileTariffShpdSliceItem>) => {
      state.status.added = true;
      state.status.touched = true;
      state.mobileTariffShpd = action.payload || initialState.mobileTariffShpd;
    },
    resetMobileTariffToInitial: (state) => {
      state.status.added = false;
      state.status.touched = false;
      state.mobileTariff = initialState.mobileTariff;
      state.mobileTariffShpd = initialState.mobileTariffShpd;
      state.mobileTouchedServices = initialState.mobileTouchedServices;
      state.totalPriceMobile = initialState.totalPriceMobile;
      state.promotionTotalPriceMobile = initialState.promotionTotalPriceMobile;
    },
    changeMobileServices: (state, action: PayloadAction<MobileService[]>) => {
      state.mobileTouchedServices = action.payload;
    },
    changeTotalPriceMobile: (state, action: PayloadAction<number>) => {
      state.totalPriceMobile = action.payload;
    },
    changePromotionTotalPriceMobile: (state, action: PayloadAction<number | undefined | null>) => {
      state.promotionTotalPriceMobile = action.payload;
    },
    changeTotalPriceMobileShpd: (state, action: PayloadAction<number>) => {
      state.totalPriceMobileShpd = action.payload;
    },
    changePromotionTotalPriceMobileShpd: (state, action: PayloadAction<number | undefined | null>) => {
      state.promotionTotalPriceMobileShpd = action.payload;
    },
  },
});

export const {
  changeMobileStatus,
  resetMobileTariffToInitial,
  changeMobileTariff,
  changeMobileTariffShpd,
  changeMobileServices,
  changeTotalPriceMobile,
  changePromotionTotalPriceMobile,
  changeTotalPriceMobileShpd,
  changePromotionTotalPriceMobileShpd,
} = MobileTariffSlice.actions;

export default MobileTariffSlice.reducer;
