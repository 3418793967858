import { UseFormReturnType } from '@mantine/form';
import { addDays, set } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ItemStatus } from '@/shared/ui-kit/form/accordion';

export const CONSTANTS = {
  RADIOS: {
    delivery: {
      items: [
        {
          value: 'true',
          label: 'Бесплатная доставка курьером',
          description: 'Курьерская доставка Sim-карт временно осуществляется в городах Симферополь и Севастополь',
        },
        {
          value: 'false',
          label: 'Заберу в отделении',
          description: 'Вы можете забрать SIM-карту в любом салоне Волны',
        },
      ],
      control: 'Способ получения SIM-карты',
    },
    payment: {
      items: [
        { value: 'online', label: 'Онлайн', description: 'Оплата банковской картой' },
        {
          value: 'offline',
          label: 'Курьеру/в отделении',
          description: 'Оплата курьеру/в отделении наличными/картой при получении SIM-карты',
        },
      ],
      control: 'Выберите способ оплаты',
    },
    exactTime: {
      items: [
        {
          value: 'false',
          label: 'В ближайшее время',
          description: 'Оператор перезвонит вам в течение 15 минут в рабочее время',
        },
        {
          value: 'true',
          label: 'Указать время',
          description: 'Оператор свяжется с вами в точно назначенный день и время',
        },
      ],
      control: 'Когда вам позвонить?',
    },
  },
  info: {
    label: {
      withHI:
        'Укажите свои контактные данные. Технический специалист заранее свяжется с вами для согласования даты и времени подключения интернета.',
      withHIClue: 'Укажите имя, телефон и адрес подключения',
      delivery:
        'Укажите свои контактные данные. Курьер заранее свяжется с вами для согласования места и времени доставки.',
      deliveryClue: 'Приём заявок на доставку SIM-карт осуществляется с понедельника по пятницу с 9:00 до 18:00',
      point: 'Укажите свои контактные данные, а также выберите удобный для вас салон связи.',
      pointClue: 'Укажите имя, телефон и выберите салон связи',
    },
    name: {
      placeholder: 'Ваше имя',
      label: 'Как к вам обращаться?',
    },
    whoRecommended: 'Кто порекомендовал (необязательно)',
    whoRecommendedTooltip: 'Введите номер того, кто порекомендовал вам подключиться к домашнему интернету Волны.',
    phoneLabel: 'Номер телефона',
    emailLabel: 'Email (необязательно)',
    address: {
      label: 'Адрес доставки',
      hi_label: 'Адрес подключения',
      placeholder: 'Регион, город, улица, дом, квартира',
    },
    control: 'Личные данные',
    filledControl: 'Заполнены',
  },
  map: {
    label: 'Выберите салон связи',
    searchPlaceholder: 'Поиск салона',
    selectedLabel: 'Выбран салон связи по адресу',
  },
  validationLabels: {
    required: 'Это поле обязательное',
    name: 'Укажите ваше имя',
    phone: 'Укажите контактный номер',
    invalidPhone: 'Некорректный номер',
    email: 'Некорректный email',
    address: 'Укажите адрес доставки',
    point: 'Выберите отделение на карте или введите адрес вручную',
  },
  confirmation: {
    control: 'Подтверждение заказа',
    filledControl: 'Подтвержден',
    delivery: 'Доставка SIM-карты по адресу',
    online: 'Оплата онлайн банковской картой',
    offline: 'Оплата наличными/картой при получении SIM-карты',
    connection: 'Адрес подключения',
  },
  sms: {
    control: 'Одноразовый SMS-код',
    description: 'Введите 4х-значный код из SMS, который мы отправили на номер',
    code_again: 'Получить код еще раз',
    not_coming: 'Не приходит SMS?',
  },
};

export enum Sections {
  delivery,
  // payment,
  info,
  confirmation,
  sms,
}

export const SECTIONS_NAMES = {
  [Sections.delivery]: 'delivery',
  // [Sections.payment]: 'payment',
  [Sections.info]: 'info',
  [Sections.confirmation]: 'confirmation',
  [Sections.sms]: 'sms',
};

export const initialDate = () => {
  const now = utcToZonedTime(new Date(), 'Europe/Moscow');

  return now.getHours() >= 18
    ? set(addDays(now, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    : set(now, { minutes: 0, seconds: 0, milliseconds: 0 });
};

export const initialValues: FormInitialValues = {
  delivery: '',
  // payment: '',
  info: {
    name: '',
    contact_phone: '',
    email: '',
    address: '',
    has_citizenship: false,
    exactTime: 'false',
    date: null,
    point: '',
    whoRecommended: '',
  },
  confirmation: false,
  acceptPromotion: false,
};

export interface FormInitialValues {
  delivery?: 'true' | 'false' | '';
  // payment?: 'online' | 'offline';
  info: {
    name: string;
    contact_phone: string;
    email?: string;
    address?: string;
    has_citizenship: boolean;
    exactTime?: 'true' | 'false' | '';
    date?: Date | null;
    point?: string;
    whoRecommended?: string;
  };
  confirmation: boolean;
  acceptPromotion: boolean;
}

export interface SectionProps {
  form: UseFormReturnType<FormInitialValues>,
  status?: ItemStatus,
  setOpenedSection?: (val: string) => void;
  withHI?: boolean;
}

export interface SectionDataType {
  value: string;
  panel: JSX.Element;
  control: JSX.Element | string;
  status: ItemStatus | undefined;
  internet?: boolean;
};