import { ReactNode, useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import cn from 'classnames';
import { Header } from '@/widgets/header/header';
import { Footer } from '@/widgets/footer/footer';
import { FooterCardsBlock } from '@/widgets/footer-cards/footer-cards';
import { Sidebar } from '@/widgets/sidebar/sidebar';
import { Path } from '@/shared/constants/links';
import { storage } from '@/shared/lib/storage';
import { STORAGE_KEYS } from '@/shared/constants/storage-keys';
import { AboutCompanyType } from '@/store/api/about-company/about-company.type';
import { useLegalData } from '@/shared/constants/use-legal-data';
import styles from './layout.module.scss';

export function Layout({ children, legalData }: { children: ReactNode, legalData?: Pick<AboutCompanyType, 'privacy_policy'> }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [withoutFooterCards, setWithoutFooterCards] = useState(false);
  const pathname = usePathname();
  const isMainPage = pathname === Path.main;
  useLegalData(legalData);
  useEffect(() => {
    const storageWithoutFooterCards = storage.get(STORAGE_KEYS.withoutFooterCards);
    const parsedWithoutFooterCards = storageWithoutFooterCards ? JSON.parse(storageWithoutFooterCards) : false;
    setWithoutFooterCards(parsedWithoutFooterCards);
  }, [pathname]);

  const onSidebarOpen = () => {
    setSidebarOpen(true);
  };

  const onSidebarClose = () => {
    setSidebarOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.centralColumn}>
        <div className={cn(styles.header, sidebarOpen && styles.headerSidebarOpened)}>
          <Header>
            <Sidebar isOpen={sidebarOpen} onOpen={onSidebarOpen} onClose={onSidebarClose} />
          </Header>
        </div>
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>
          {withoutFooterCards && isMainPage ? null : <FooterCardsBlock /> }
          <Footer />
        </div>
      </div>
    </div>
  );
}
